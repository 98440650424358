import React, {useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TimePicker
} from "antd";
import {DeleteOutlined, EditFilled, LoadingOutlined} from "@ant-design/icons";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

const rowStyle = {padding: '4px'};

export default function NotificationCampaingEdit({campaign, visible, onOk, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [record, setRecord] = useState({});
  let [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    setRecord({...campaign});
  }, [campaign, visible]);

  useEffect(() => {
    console.log(record);
  }, [record]);

  return (
    <Modal
      destroyOnClose={true}
      className="size-for-middle-window"
      style={{top: 10}}
      title={"Рассылка"}
      visible={visible}
      onOk={() => {
        if(!record.title) {
          message.info("Укажите наименование");
          return;
        }
        if(!record.bot_type) {
          message.info("Укажите типа рассылки");
          return;
        }
        if(!record.is_group && !record.chat_id) {
          message.info("Для персональной рассылки нужно указать ID адресата");
          return;
        }
        if(!record.message) {
          message.info("Укажите текст сообщения");
          return;
        }
        if(!record.start_dt) {
          message.info("Укажите дату начала рассылки");
          return;
        }
        if(!record.end_dt) {
          message.info("Укажите дату окончания рассылки");
          return;
        }
        if(moment(record.end_dt) < moment(record.start_dt)) {
          message.info("Дата начала рассылки должна быть раньше даты окончания");
          return;
        }
        if(!record.send_time || record.send_time.length < 1) {
          message.info("Необходимо указать не менее одного времени рассылки");
          return;
        }
        onOk(record);
      }}
      onCancel={() => {
        onCancel();
      }}>
      {
        loading ? (
          <div style={{margin: '20px auto', width: '100%', textAlign: 'center', fontSize: '2em'}}>
            <LoadingOutlined/>
          </div>
        ) : (
          <>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Наименование</Col>
              <Col span={16}>
                <Input
                  placeholder={"Наименование"}
                  value={record?.title}
                  onChange={(e) => { setRecord({...record, title: e.target.value} ) }}
                  allowClear={true}
                  width={"100%"}/>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Тип рассылки</Col>
              <Col span={16}>
                <Select
                  style={{ width: '100%' }}
                  value={record?.bot_type}
                  onChange={(value) => {setRecord({...record, bot_type: value})}}
                >
                  <Option key={"DRIVER_BOT"} value={"DRIVER_BOT"}>{"Бот Водитель"}</Option>
                  <Option key={"MECHANIC_BOT"} value={"MECHANIC_BOT"}>{"Бот Механик"}</Option>
                  <Option key={"NOTIFICATION_BOT"} value={"NOTIFICATION_BOT"}>{"Бот уведомлений"}</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Отключено</Col>
              <Col span={16}>
                <Checkbox
                  checked={record?.is_disabled}
                  onChange={(e) => {
                    setRecord({...record, is_disabled: e.target.checked });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Групповой</Col>
              <Col span={16}>
                <Checkbox
                  checked={record?.is_group === true}
                  onChange={(e) => {
                    setRecord({...record, is_group: e.target.checked });
                  }}
                />
              </Col>
            </Row>
            {
              !record?.is_group
                ? (
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={5}>ID адресата</Col>
                    <Col span={16}>
                      <Input
                        placeholder={"Telegram ID"}
                        value={record?.chat_id}
                        onChange={(e) => { setRecord({...record, chat_id: e.target.value} ) }}
                        allowClear={true}
                        width={"100%"}/>
                    </Col>
                  </Row>
                ) : null
            }
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Дата начала</Col>
              <Col span={16}>
                <DatePicker
                  format="DD.MM.YYYY" placeholder="Дата начала"
                  value={record?.start_dt ? moment(record.start_dt) : null}
                  onChange={(e) => { setRecord({...record, ...{start_dt: e.format("YYYY-MM-DD")}}) }}
                  allowClear={false}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Дата окончания</Col>
              <Col span={16}>
                <DatePicker
                  format="DD.MM.YYYY" placeholder="Дата начала"
                  value={record?.end_dt ? moment(record.end_dt) : null}
                  onChange={(e) => { setRecord({...record, ...{end_dt: e.format("YYYY-MM-DD")}}) }}
                  allowClear={false}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Время рассылки</Col>
              <Col span={16}>
                <>
                  <Space direction={"horizontal"} style={{marginBottom: 10}} align={"right"}>
                    <TimePicker onChange={(time, timeString) => { setSelectedTime(time) }}
                                value={selectedTime}
                                style={{width: 200}} />
                    <Button
                      type="primary"
                      disabled={selectedTime === null}
                      onClick={() => {
                        let newTime = selectedTime.format("HH:mm:ss");
                        let sendTime = record.send_time;
                        if(!sendTime) {
                          sendTime = [newTime];
                        } else {
                          if(sendTime.indexOf(newTime) >= 0) {
                            message.warn("Указанное время уже добавлено");
                            return;
                          } else {
                            sendTime.push(selectedTime.format("HH:mm:ss"));
                          }
                        }
                        setRecord({...record, send_time: sendTime.sort()})
                      }}
                      >
                        Добавить
                    </Button>
                  </Space>
                  <Table
                    dataSource={record?.send_time?.map(el => { return {id: el, title: el} })}
                    columns={[
                      {
                        title: "",
                        align: "center",
                        width: 30,
                      },
                      {
                        title: "",
                        width: 50,
                        align: "center",
                        dataIndex: "id",
                        render: function(text, item, index) {
                          return (
                            <Space direction={"horizontal"}>
                              <Button type="link" icon={<DeleteOutlined/>} onClick={() => {
                                setRecord({...record, send_time: record.send_time.filter(el => el !== item.id)})
                              }}/>
                            </Space>
                          );
                        }
                      },
                      {
                        title: 'Время',
                        render: function(text, record, index) {
                          return record.title;
                        }
                      },
                      {
                        title: "",
                        align: "center",
                        width: 30,
                      },
                    ]}
                    size='small'
                    key="id"
                    pagination={{position: 'topRight', defaultPageSize: 5}}
                  />
                </>
              </Col>
            </Row>
            <Row gutter={[0, 16]} style={rowStyle}>
              <Col span={5}>Сообщение</Col>
              <Col span={16}>
                <TextArea
                  rows={5}
                  value={record?.message}
                  onChange={(e) => { setRecord({...record, message: e.target.value} ) }}
                  width={"100%"}/>
              </Col>
            </Row>
          </>

        )
      }
    </Modal>
  )
}