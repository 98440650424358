import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../services/ApiClient";
import {Col, message, Modal, Row, Select} from "antd";

const { Option } = Select;

const rowStyle = {padding: '4px'};

export default function VehicleParkChangeDialog({onOK, onCancel}) {

  let [loading, setLoading] = useState(false);
  let [taxiParks, setTaxiParks] = useState([]);
  let [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiClient.getParks()
      .then(res => { setTaxiParks(res.records); })
      .catch(err => { message.error(err); })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      title={"Перенос ТС"}
      visible={true}
      confirmLoading={loading}
      onOk={() => {
        onOK(selectedPark)
      }}
      onCancel={onCancel}>
      <>
        <Row gutter={[0, 16]} style={rowStyle}>
          <Col md={6}>Парк</Col>
          <Col md={18}>
            <Select
              showSearch
              allowClear
              value={selectedPark}
              style={{width: "100%"}}
              onChange={(value) => {
                setSelectedPark(value);
              }}
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {
                taxiParks.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
          </Col>
        </Row>
      </>
    </Modal>
  )
}