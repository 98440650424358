import React, {useEffect, useState} from "react";
import {Button, message, Modal, Space, Switch, Table} from "antd";
import {apiClient} from "../../../../../services/ApiClient";
import {DeleteOutlined, EditFilled} from "@ant-design/icons";

import moment from "moment";
import NotificationCampaingEdit from "./NotificationCampaingEdit";

export default function NotificationCampaings({active}) {
  let [loading, setLoading] = useState(false);
  let [update, setUpdate] = useState(0);
  let [editCampaign, setEditCampaign] = useState(null);
  let [records, setRecords] = useState([]);
  let [selected, setSelected] = useState([]);

  useEffect(() => {
    apiClient.getNotificationCampaings({})
      .then(res => {
        setRecords(res.records)
      })
      .catch(err => {
        console.error(err);
        message.error("Ошибка: не удалось загрузить данные")
      })
  }, [active, update]);

  const removeSelected = () => {
    Modal.confirm({
      title:  "Удаление",
      content: "Вы уверены, что хотите удалить записи?",
      icon: <DeleteOutlined/>,
      visible: true,
      onOk() {
        setLoading(true);
        apiClient.removeNotificationCampaigns(selected)
          .then(res => {
            message.info("Записи удалены");
            setUpdate(prevState => prevState + 1);
            setSelected([]);
          })
          .catch(err => {
            console.error(err);
            message.error("Ошибка: не удалось удалить данные")
          })
          .finally(() => { setLoading(false) });
      },
    });
  };

  return (
    <>
      <Space direction={"horizontal"} style={{marginBottom: 10}} align={"right"}>
        <Button type="primary" onClick={() => { setEditCampaign({id: 0, bot_type: "DRIVER_BOT"}) }} loading={loading}>
          Создать рассылку
        </Button>
        {
          selected.length > 0
            ? (<Button type={"danger"} onClick={() => {
                removeSelected()
              }} loading={loading}>
                Удалить
              </Button>
            ) : null
        }
      </Space>
      <Table
        dataSource={records}
        scroll={{x: 1000, y: 600}}
        sticky
        columns={[
          {
            title: "",
            align: "center",
            width: 30,
          },
          Table.SELECTION_COLUMN,
          {
            title: "",
            width: 50,
            align: "center",
            dataIndex: "id",
            render: function(text, record, index) {
              return (
                <Space direction={"horizontal"}>
                  <Button type="link" icon={<EditFilled/>} onClick={() => { setEditCampaign(record); }}/>
                </Space>
              );
            }
          },
          {
            title: 'Имя',
            dataIndex: 'title',
          },
          {
            title: 'Отключено',
            dataIndex: 'is_disabled',
            width: 120,
            render: (text, record, index) => {
              return (
                <Switch checked={!record.is_disabled}
                        checkedChildren={"Отключить"}
                        unCheckedChildren={"Включить"}
                        onChange={(checked, event) => {
                          record.is_disabled = !checked;
                          apiClient.updateNotificationCampaign(record.id, {is_disabled: record.is_disabled})
                            .then(res => setUpdate(prevState => prevState + 1))
                            .catch(err => message.error(err));
                        }}
                />
              )
            },
          },
          {
            title: 'Дата начала',
            width: 150,
            render: (text, record, index) => {
              return moment(record.start_dt).format("DD.MM.YYYY HH:mm")
            },
          },
          {
            title: 'Дата окончания',
            width: 150,
            render: (text, record, index) => {
              return moment(record.end_dt).format("DD.MM.YYYY HH:mm")
            },
          },
          {
            title: "",
            align: "center",
            width: 30,
          },
        ]}
        size='small'
        rowKey="id"
        rowSelection={{
          selectedRecords: selected,
          onChange: (selectedRowKeys) => {
            setSelected(selectedRowKeys)
          }
        }}
        pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
      />
      {
        editCampaign !== null && (
          <NotificationCampaingEdit
            visible={editCampaign !== null}
            campaign={editCampaign}
            onOk={(record) => {
              setEditCampaign(null);
              setLoading(true);
              if(record.id > 0) {
                apiClient.updateNotificationCampaign(record.id, record)
                  .then(res => {
                    setUpdate(prevState => prevState + 1);
                    message.info("Сохранено успешно");
                  })
                  .catch(err => {
                    console.error(err);
                    message.error("Ошибка: не удалось обновить данные");
                  })
                  .finally(() => { setLoading(false); });
              } else {
                apiClient.createNotificationCampaign(record)
                  .then(res => {
                    setUpdate(prevState => prevState + 1);
                    message.info("Сохранено успешно");
                  })
                  .catch(err => {
                    console.error(err);
                    message.error("Ошибка: не удалось сохранить данные");
                  })
                  .finally(() => { setLoading(false); })
              }
            }}
            onCancel={() => {
              setEditCampaign(null);
            }}
          />
        )
      }
    </>
  )
}